export default {
  cake: {
    56: '0xfE56e1B784E7C44527D14786272346AfBc588EBD',
    97: '',
  },
  masterChef: {
    56: '0x7d0FEDa1a2Ac5010fDc637821C2b308ecc9C4E3A', 
    97: '',
  },
  presale: {
    56: '0x30A64CD5daCb2861CAf4B4320ef445A616F24ca4',
    97: '',
    137: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
